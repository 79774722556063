export enum GLOBAL_ROUTES {
  SIGN_UP = "/signup",
  JOIN = "/join",
  LOGIN = "/login",
  SEARCH = "/search",
  VERIFICATION = "/verification",
  FORGOT_PASSWORD = "/forgot-password",
  NEW_PASSWORD = "/new-password",
  COMPLETE_YOUR_ACCOUNT = "/complete",
  RISK_INSIGHTS = "/risk-insights",
  INTERNAL = "/internal", // TODO: 99% to delete it after time
  PROFILE = "/profile",
  SETTINGS = "/settings",
  MY_OFFERS = "/my-offers",
  FIRST_SURVEY = "/first-survey",
  VERIFY_IDENTITY = "/verify-identity"
}

export enum SETTINGS_SUBROUTES {
  MY_ACCOUNT = "my-account",
  MY_IDENTITY = "my-identity",
  COMPENSATION_PREFERENCES = "compensation-preferences",
  PAYMENT_HISTORY = "payment-history",
  TERMS_AND_CONDITIONS = "terms",
  SUPPORT = "support",
}