import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiResponse } from "api/types";
import { User } from "api/dtos";
import { apiRequests } from "api";
import { storageUtil } from "utils";
import { config } from "config";

export const loginAsync = createAsyncThunk(
  "auth/login",
  async (
    {
      email,
    }: {
      email: string
    },
    { dispatch },
  ) => {
    const response = await apiRequests.login(email);
    // Persist the token we received for future requests
    if (response && response.success && response.data) {
      storageUtil.set(config.storage.TOKEN_KEY, response.data.token);
      storageUtil.set(config.storage.REFRESH_TOKEN_KEY, response.data.refreshToken);
    }
    dispatch(getUserAsync());
  },
);
export const getUserAsync = createAsyncThunk("auth/getUser", async (): Promise<ApiResponse<User>> => {
  // The value we return becomes the `fulfilled` action payload
  const response = await apiRequests.getMyUser();
  if (response && response.success && response.data) {
    storageUtil.set(config.storage.USER_KEY, response.data);
  }
  return response;
});