export enum HttpMethod {
  GET = "GET",
  DELETE = "DELETE",
  POST = "POST",
  PATCH = "PATCH",
  PUT = "PUT",
}

export interface RequestConfig {
  path: string;
  requiresAuth?: boolean;
  token?: string;
  body?: object;
  queryParams?: object;
  headers?: Record<string, string>;
  timeout?: number;
  autoRefreshToken?: boolean;
}

export interface RequestConfigWithMethod extends RequestConfig {
  method: HttpMethod;
}
