import React, { useEffect, useMemo, useState } from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import { BottomNavigationActionWrapper, Footer } from "./styles";
import { authorizedBottomBarButtons, unauthorizedBottomBarButtons } from "./constants";
import { useNavigate } from "react-router-dom";
import { GLOBAL_ROUTES } from "routes";
import { useLocation } from "react-router";
import { selectIsAuthorized } from "store";
import { useSelector } from "react-redux";
import { Paper } from "@mui/material";

export const BottomBar = () => {
  const location = useLocation();
  const [value, setValue] = useState(location.pathname as GLOBAL_ROUTES);
  const isAuthorized = useSelector(selectIsAuthorized);
  const navigate = useNavigate();
  const bottomButtons = useMemo(() => {
    return isAuthorized ? authorizedBottomBarButtons : unauthorizedBottomBarButtons;
  }, [isAuthorized]);
  const handleChange = (event: React.SyntheticEvent, newValue: GLOBAL_ROUTES) => {
    navigate(newValue);
  };
  useEffect(() => {
    setValue(location.pathname as GLOBAL_ROUTES);
  }, [location.pathname]);
  return (
    <Footer>
      <Paper sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}>
        <BottomNavigation
          showLabels
          value={value}
          onChange={handleChange}>
          {bottomButtons.map(item => (
            <BottomNavigationActionWrapper
              key={item.value}
              {...item} />))}
        </BottomNavigation>
      </Paper>
    </Footer>
  );
};

