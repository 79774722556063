import { Header, StyledHeaderAvatar, StyledHeaderBadge } from "./styles";
import { ArrowDropDown } from "@mui/icons-material";
import { CompanyLogo } from "components/CompanyLogo/CompanyLogo";
import { selectUser, useAppSelector } from "store";

export const HeaderBar = () => {
  const user = useAppSelector(selectUser);

  return (
    <Header>
      <CompanyLogo />
      {user && <StyledHeaderBadge
        badgeContent={<ArrowDropDown />}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <StyledHeaderAvatar>OP</StyledHeaderAvatar>
      </StyledHeaderBadge>}
    </Header>
  );
};

