import { StatusCodes } from "http-status-codes";

export interface ApiErrorMessage {
  errorMessage?: string;
  status?: StatusCodes;
  stackTrace?: string;
}

export class ApiError extends Error {
  public status: StatusCodes;
  public stackTrace?: string;

  constructor({ errorMessage, status = StatusCodes.INTERNAL_SERVER_ERROR, stackTrace }: ApiErrorMessage = {}) {
    super(errorMessage);
    this.status = status;
    this.stackTrace = stackTrace;
  }
}
