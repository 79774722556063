import { BottomNavigationActionProps } from "@mui/material/BottomNavigationAction/BottomNavigationAction";
import SearchIcon from "@mui/icons-material/Search";
import React from "react";
import LoginIcon from "@mui/icons-material/Login";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import { GLOBAL_ROUTES } from "routes";
import { AccountCircleOutlined, CloudOutlined, SettingsOutlined } from "@mui/icons-material";

export interface BottomButtonProps extends BottomNavigationActionProps {
  value: GLOBAL_ROUTES;
}

export const unauthorizedBottomBarButtons: BottomButtonProps[] = [{
  label: "Search",
  value: GLOBAL_ROUTES.SEARCH,
  icon: <SearchIcon />,
  disabled: true,
},
{
  label: "Login",
  value: GLOBAL_ROUTES.LOGIN,
  icon: <LoginIcon />
},
{
  label: "Sign Up",
  value: GLOBAL_ROUTES.SIGN_UP,
  icon: <PersonAddAlt1Icon />
}
];

export const authorizedBottomBarButtons: BottomButtonProps[] = [{
  label: "My Offers",
  value: GLOBAL_ROUTES.MY_OFFERS,
  icon: <CloudOutlined />
},
{
  label: "Profile",
  value: GLOBAL_ROUTES.PROFILE,
  icon: <AccountCircleOutlined />,
},
{
  label: "Search",
  value: GLOBAL_ROUTES.SEARCH,
  icon: <SearchIcon />,
  disabled: true,
},
{
  label: "Settings",
  value: GLOBAL_ROUTES.SETTINGS,
  icon: <SettingsOutlined /> 
}
];

export const pageWithoutFooterPathList = [GLOBAL_ROUTES.INTERNAL];