import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import { useCustomLayout } from "hooks";
import { HEADER_BAR_HEIGHT } from "components/HeaderBar/styles";

export const APP_BODY_PADDING = 24;

export const AppBody = styled(Box)(({ theme }) => {
  const { externalPadding } = useCustomLayout();
  return {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: theme.palette.consuli.officialPrimary,
    flexGrow: 1,
    overflowY: "auto",
    width: "100%",
    padding: externalPadding ? `${APP_BODY_PADDING}px ${APP_BODY_PADDING}px 0` : "0",
    marginTop: `${HEADER_BAR_HEIGHT}px`,
  };
});