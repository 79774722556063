import { Components } from "@mui/material";
import { consuliColors } from "./colors";

export const components: Components = {
  MuiButton: {
    defaultProps: {
      disableElevation: true,
    },
    variants: [
      {
        props: {
          color: "primary",
        },
        style: {
          fontWeight: 600,
          background: consuliColors.officialPrimary
        }
      },
      {
        props: {
          color: "secondary",
        },
        style: {
          background: "transparent"
        }
      }
    ],
    styleOverrides: {
      root: {
        textTransform: "none",
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderRadius: 0,
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      sizeSmall: {
        "& input::placeholder": {
          fontSize: 14,
        },
      },
    },
  },
};