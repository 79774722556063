import styled from "@emotion/styled";
import { Avatar, Badge } from "@mui/material";
import { badgeClasses } from "@mui/material/Badge";
import { svgIconClasses } from "@mui/material/SvgIcon";

const HEADER_AVATAR_SIZE = 36;
const HEADER_BADGE_ICON_SIZE = 18;
const HEADER_BADGE_SIZE = 20;
export const HEADER_BAR_HEIGHT = 60;

export const Header = styled("header")(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  background: theme.palette.consuli.white,
  height: HEADER_BAR_HEIGHT,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
  padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
}));

export const StyledHeaderAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.consuli.officialPrimary,
  height: `${HEADER_AVATAR_SIZE}px`,
  width: `${HEADER_AVATAR_SIZE}px`,
  fontSize: 18,
}));

export const StyledHeaderBadge = styled(Badge)(({ theme }) => ({
  [`& .${badgeClasses.badge}`]: {
    backgroundColor: theme.palette.consuli.white,
    height: HEADER_BADGE_SIZE,
    width: HEADER_BADGE_SIZE,
    boxShadow: "0px 0px 9px 5px rgba(0,0,0,0.10)",
    bottom: 4,
  },
  [`& .${svgIconClasses.root}`]: {
    width: HEADER_BADGE_ICON_SIZE,
    height: HEADER_BADGE_ICON_SIZE,
  },
}));