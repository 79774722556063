import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { GLOBAL_ROUTES, SETTINGS_SUBROUTES } from "./routes";
import { NotFoundPage } from "pages/NotFoundPage/NotFoundPage";
import { AppBody } from "components/AppBody";
import { ProtectedRoute } from "./ProtectedRoute";
import { Loader } from "components/Loader";

const SignUpPage = lazy(() => import("pages/SignUpPage"));
const LoginPage = lazy(() => import("pages/LoginPage"));
const VerificationNumberPage = lazy(() => import("pages/VerificationNumberPage"));
const ForgotPasswordPage = lazy(() => import("pages/ForgotPasswordPage"));
const NewPasswordPage = lazy(() => import("pages/NewPasswordPage"));
const InternalPage = lazy(() => import("pages/InternalPage"));
const FirstSurveyPage = lazy(() => import("pages/FirstSurveyPage"));
const SignUpOrLoginPage = lazy(() => import("pages/SignUpOrLogin"));
const MyOffersPage = lazy(() => import("pages/MyOffersPage"));
const ProfilePage = lazy(() => import("pages/ProfilePage"));
const SettingsPage = lazy(() => import("pages/SettingsPage"));
const SettingsMenu = lazy(() => import("pages/SettingsPage/subpages/SettingsMenu"));
const CompensationPreferencesSubpage = lazy(() => import("pages/SettingsPage/subpages/CompensationPreferences"));
const MyAccountSubpage = lazy(() => import("pages/SettingsPage/subpages/MyAccount"));
const MyIdentitySubpage = lazy(() => import("pages/SettingsPage/subpages/MyIdentity"));
const PaymentHistorySubpage = lazy(() => import("pages/SettingsPage/subpages/PaymentHistory"));
const SupportSubpage = lazy(() => import("pages/SettingsPage/subpages/Support"));
const TermsAndConditionsSubpage = lazy(() => import("pages/SettingsPage/subpages/TermsAndConditions"));

export const RootRouter: React.FC = () => {
  return (
    <AppBody>
      <Suspense
        fallback={
          <Loader />
        }>
        <Routes>
          <Route path={GLOBAL_ROUTES.SIGN_UP} element={<SignUpPage />} />
          <Route path={GLOBAL_ROUTES.LOGIN} element={<LoginPage />} />
          <Route path={GLOBAL_ROUTES.VERIFICATION} element={<VerificationNumberPage />} />
          <Route path={GLOBAL_ROUTES.FORGOT_PASSWORD} element={<ForgotPasswordPage />} />
          <Route path={GLOBAL_ROUTES.NEW_PASSWORD} element={<NewPasswordPage />} />
          <Route path={GLOBAL_ROUTES.SEARCH} element={<NotFoundPage />} />
          <Route path={GLOBAL_ROUTES.INTERNAL} element={<InternalPage />} />
          <Route path="/" element={<ProtectedRoute />}>
            <Route path={GLOBAL_ROUTES.MY_OFFERS} element={<MyOffersPage />} />
            <Route path={GLOBAL_ROUTES.FIRST_SURVEY} element={<FirstSurveyPage />} />
            <Route path={GLOBAL_ROUTES.PROFILE} element={<ProfilePage />} />
            <Route path={GLOBAL_ROUTES.SETTINGS} element={<SettingsPage />}>
              <Route path='' element={<SettingsMenu />} />
              <Route
                path={SETTINGS_SUBROUTES.COMPENSATION_PREFERENCES}
                element={<CompensationPreferencesSubpage />}
              />
              <Route path={SETTINGS_SUBROUTES.MY_ACCOUNT} element={<MyAccountSubpage />} />
              <Route path={SETTINGS_SUBROUTES.MY_IDENTITY} element={<MyIdentitySubpage />} />
              <Route
                path={SETTINGS_SUBROUTES.PAYMENT_HISTORY}
                element={<PaymentHistorySubpage />}
              />
              <Route path={SETTINGS_SUBROUTES.SUPPORT} element={<SupportSubpage />} />
              <Route
                path={SETTINGS_SUBROUTES.TERMS_AND_CONDITIONS}
                element={<TermsAndConditionsSubpage />}
              />
            </Route>
          </Route>
          <Route path="/" element={<SignUpOrLoginPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Suspense>
    </AppBody>
  );
};
