import { StatusCodes } from "http-status-codes";
import qs from "qs";
import urljoin from "url-join";
import { convertAllDatesInObjectRecursive, isJson } from "utils";
import { ApiError, ApiResponse, RequestConfigWithMethod } from "../types";

export const networkRequest = async <T>(config: RequestConfigWithMethod): Promise<ApiResponse<T>> => {
  let headers = config.headers;

  if (config.requiresAuth) {
    if (!config.token) {
      throw new Error("if a request requires auth, a token is required");
    }
    headers = { ...headers, Authorization: `Bearer ${config.token}` };
  }

  let body: any = config.body;
  if (
    config.headers &&
    "Content-Type" in config.headers &&
    config.headers["Content-Type"].includes("application/json")
  ) {
    body = config.body ? JSON.stringify(config.body) : undefined;
  }

  const url = config.queryParams ? urljoin(config.path, `?${qs.stringify(config.queryParams)}`) : config.path;
  const abortController = new AbortController();

  const response: Response = await Promise.race([
    fetch(url, {
      method: config.method,
      headers,
      body,
      signal: abortController.signal,
    }),
    new Promise<Response>((_, reject) =>
      setTimeout(() => {
        abortController.abort();
        return reject(
          new ApiError({
            errorMessage: `request to ${config.path} has timed out`,
            status: StatusCodes.REQUEST_TIMEOUT,
          }),
        );
      }, config.timeout),
    ),
  ]);

  const responseText = await response.text();
  const isResponseJson = isJson(responseText);

  // fetch throws error only upon actual network error, 4xx & 5xx responses do not throw
  if (!response.ok) {
    if (isResponseJson) {
      const errorResponse = JSON.parse(responseText) as ApiResponse<any>;
      throw new ApiError({
        errorMessage: errorResponse.error,
        status: response.status,
        stackTrace: errorResponse.stackTrace,
      });
    } else {
      throw new ApiError({
        errorMessage: `An unknown error with status code ${response.status} has occured`,
        status: response.status,
      });
    }
  }

  return isResponseJson ? convertAllDatesInObjectRecursive(JSON.parse(responseText)) : null;
};
