import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { selectUser, useAppSelector } from "store";
import { GLOBAL_ROUTES } from "./routes";
import { useEffect } from "react";
import { useLocation } from "react-router";

export const ProtectedRoute = () => {
  const user = useAppSelector(selectUser);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  //TODO temporary solution
  useEffect(() => {
    if(user && pathname === "/") {
      navigate(GLOBAL_ROUTES.MY_OFFERS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return user ? <Outlet /> : <Navigate to={GLOBAL_ROUTES.LOGIN} replace />;
};
