import styled from "@emotion/styled";
import { BottomNavigationAction } from "@mui/material";
import { bottomNavigationClasses } from "@mui/material/BottomNavigation";

export const BOTTOM_BAR_HEIGHT = 60;

export const Footer = styled("footer")(({ theme }) => ({
  zIndex: 9999,
  width: "100%",
  [`& .${bottomNavigationClasses.root}`]: {
    background: theme.palette.consuli.lightGrey,
    width: "100%",
    height: BOTTOM_BAR_HEIGHT,
  },
}));

export const BottomNavigationActionWrapper = styled(BottomNavigationAction)({
  flex: "none",
  minWidth: "70px",
  "&.Mui-disabled": {
    opacity: .3
  }
});
