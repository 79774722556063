import { useLocation } from "react-router";
import { GLOBAL_ROUTES, SETTINGS_SUBROUTES } from "routes";
import { pageWithoutFooterPathList } from "components/BottomBar";

const disabledPaddingPages: GLOBAL_ROUTES[] =
    [GLOBAL_ROUTES.SETTINGS, GLOBAL_ROUTES.MY_OFFERS, GLOBAL_ROUTES.FIRST_SURVEY, GLOBAL_ROUTES.PROFILE];

export const useCustomLayout = () => {
  const { pathname } = useLocation();
  const lastUrlSegment = pathname.substring(pathname.lastIndexOf("/") + 1);

  const showFooter = !pageWithoutFooterPathList.includes(pathname as GLOBAL_ROUTES);
  const isSettingsSubpage = Object.values(SETTINGS_SUBROUTES).includes(lastUrlSegment as SETTINGS_SUBROUTES);
  const externalPadding = !(disabledPaddingPages.includes(pathname as GLOBAL_ROUTES) || isSettingsSubpage);

  return {
    externalPadding,
    showFooter,
  };
};