import { StatusCodes } from "http-status-codes";
import { cloneDeep, merge } from "lodash";
import urljoin from "url-join";
import { config } from "config";
import { storageUtil } from "utils";
import { apiPaths } from "../api-paths";
import { TokenRequest, TokenResponse } from "../dtos";
import { ApiError, ApiResponse, HttpMethod, RequestConfig, RequestConfigWithMethod } from "../types";
import { networkRequest } from "./network-request";

const defaultConfig: RequestConfigWithMethod = {
  method: HttpMethod.GET,
  path: "",
  requiresAuth: true,
  timeout: 150000,
  headers: {
    "Content-Type": "application/json",
  },
  autoRefreshToken: true,
};

const mergeWithDefaultConfigs = (requestConfig: RequestConfigWithMethod): RequestConfigWithMethod => {
  let defaultConfigForMerge = defaultConfig;
  if ("headers" in requestConfig) {
    defaultConfigForMerge = cloneDeep(defaultConfig);
    delete defaultConfigForMerge["headers"];
  }
  return merge({}, defaultConfigForMerge, requestConfig);
};

const getNewTokenAndSave = async (refreshToken: string): Promise<string> => {
  const body: TokenRequest = {
    refreshToken: refreshToken,
  };

  const requestConfig: RequestConfigWithMethod = {
    method: HttpMethod.POST,
    path: urljoin(config.api.API_URL, apiPaths.AUTH_TOKEN),
    requiresAuth: false,
    body,
  };
  const mergedRequestConfig = mergeWithDefaultConfigs(requestConfig);
  const response = await networkRequest<TokenResponse>(mergedRequestConfig);

  if (!response.data) {
    throw new ApiError({ errorMessage: "No token received from server" });
  }

  storageUtil.set(config.storage.TOKEN_KEY, response.data.token);
  return response.data.token;
};

const requestWithAuthRetry = async <T>(requestConfig: RequestConfigWithMethod): Promise<ApiResponse<T>> => {
  const mergedRequestConfig = mergeWithDefaultConfigs(requestConfig);
  const storageProviderToken = storageUtil.get(config.storage.TOKEN_KEY) ?? undefined;
  mergedRequestConfig.token = mergedRequestConfig.token ?? storageProviderToken;
  mergedRequestConfig.path = urljoin(config.api.API_URL, mergedRequestConfig.path);

  try {
    return await networkRequest(mergedRequestConfig);
  } catch (err) {
    console.error(err);

    // Try refreshing token automatically if possible
    if (
      err instanceof ApiError &&
      mergedRequestConfig.autoRefreshToken &&
      mergedRequestConfig.requiresAuth &&
      err.status === StatusCodes.UNAUTHORIZED
    ) {
      const storageProviderRefreshToken = storageUtil.get(config.storage.REFRESH_TOKEN_KEY);
      if (storageProviderRefreshToken) {
        const token = await getNewTokenAndSave(storageProviderRefreshToken);
        mergedRequestConfig.token = token;
        return await networkRequest(mergedRequestConfig);
      }
    }

    throw err;
  }
};

const get = async <T>(requestConfig: RequestConfig): Promise<ApiResponse<T>> => {
  return requestWithAuthRetry({
    ...requestConfig,
    method: HttpMethod.GET,
  });
};

const post = async <T>(requestConfig: RequestConfig): Promise<ApiResponse<T>> => {
  return requestWithAuthRetry({
    ...requestConfig,
    method: HttpMethod.POST,
  });
};

const patch = async <T>(requestConfig: RequestConfig): Promise<ApiResponse<T>> => {
  return requestWithAuthRetry({
    ...requestConfig,
    method: HttpMethod.PATCH,
  });
};

const put = async <T>(requestConfig: RequestConfig): Promise<ApiResponse<T>> => {
  return requestWithAuthRetry({
    ...requestConfig,
    method: HttpMethod.PUT,
  });
};

const deleteRequest = async <T>(requestConfig: RequestConfig): Promise<ApiResponse<T>> => {
  return requestWithAuthRetry({
    ...requestConfig,
    method: HttpMethod.DELETE,
  });
};

export const request = {
  get,
  post,
  patch,
  put,
  delete: deleteRequest,
};
