import { apiPaths } from "./api-paths";
import { TokenResponse, User } from "./dtos";
import { request } from "./network";
import { ApiResponse } from "./types";

const login = (email: string): Promise<ApiResponse<TokenResponse>> => {
  return request.post<TokenResponse>({
    path: apiPaths.AUTH_LOGIN,
    body: {
      email,
    },
    requiresAuth: false,
    autoRefreshToken: false,
  });
};

const getMyUser = (): Promise<ApiResponse<User>> => {
  return request.get({
    path: apiPaths.USER_ME,
  });
};

export const apiRequests = {
  login,
  getMyUser,
} as const;
