import { BottomBar } from "components/BottomBar";
import { HeaderBar } from "components/HeaderBar";
import { RootRouter } from "routes";
import { AppContainer } from "components/AppContainer";
import { useCustomLayout } from "hooks";

function App() {
  const { showFooter } = useCustomLayout();

  return (
    <AppContainer>
      <HeaderBar />
      <RootRouter />
      {showFooter && <BottomBar />}
    </AppContainer>
  );
}

export default App;
