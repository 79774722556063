export const consuliColors = {
  textPrimary: "#000",
  textSecondary: "#4f4f4f",
  officialPrimary: "#3899ff",
  officialSecondary: "#000",
  success:"#4caf4f",
  pending:"#e99f3d",
  fail: "#f55253",
  inactive: "#e6e8ea",
  black: "#000",
  white: "#fff",
  lightGrey:"#ededed",
  grey:"#e6e8ea",
  darkGrey:"#505050",
  tabColor: "#1b2733",
};

export type ThemeColors = typeof consuliColors;