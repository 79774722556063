import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestStatus } from "common/types";
import { storageUtil } from "utils";
import { User } from "api/dtos";
import { getUserAsync, loginAsync } from "./thunks";
import { Nullable } from "globalTypes";

export interface AuthState {
    user: Nullable<User>;
    error: Nullable<string>;
    status: RequestStatus;
}

const initialState: AuthState = {
  user: null,
  error: null,
  status: RequestStatus.Idle,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: state => {
      state.status = RequestStatus.Idle;
      state.user = null;
      state.error = null;
      storageUtil.clear();
    },
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
  },
  extraReducers: builder => {
    builder
    // loginAsync
      .addCase(loginAsync.pending, state => {
        state.status = RequestStatus.Loading;
      })
      .addCase(loginAsync.rejected, (state, action) => {
        state.error = action.error.message || "Unexpected error";
        state.status = RequestStatus.Failed;
      })
    // getUserAsync
      .addCase(getUserAsync.pending, state => {
        state.status = RequestStatus.Loading;
      })
      .addCase(getUserAsync.fulfilled, (state, action) => {
        state.status = RequestStatus.Idle;
        state.user = action.payload.data || null;
      })
      .addCase(getUserAsync.rejected, (state, action) => {
        state.error = action.error.message || "Unexpected error";
        state.status = RequestStatus.Failed;
      });
  },
});

export const { logout, setUser } = authSlice.actions;
export const authReducer = authSlice.reducer;
