import logo from "assets/images/consuli_logo.png";
import { Box } from "@mui/material";

export const CompanyLogo = () => {
  return (
    <Box
      component="img"
      sx={{
        width: 90,
      }}
      alt="Company logo"
      src={logo}
    />
  );
};

