import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import { maxScreenWidth } from "./constants";

export const AppContainer = styled(Box)`
  transform: translateZ(0);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  max-width: ${maxScreenWidth}px;
  margin: auto;

  & > * {
    box-sizing: border-box;
  }
`; 