import { isArray, isPlainObject, isString } from "lodash";
import { i18n } from "i18n";
import dayjs from "dayjs";

const strictFullISO8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;

/**
 * This method recursively converts a valid ISO-8601 string to a javascript Date object.
 * @param object any object that we get from the API
 * @returns the same object but with all the dates converted to valid JavaScript Date object
 */
export const convertAllDatesInObjectRecursive = <T extends object>(object: T): T => {
  for (const currKey of Object.keys(object)) {
    const currValue = (object as any)[currKey];
    if (currValue && isString(currValue) && strictFullISO8601Regex.test(currValue)) {
      ;(object as any)[currKey] = new Date(currValue);
    } else if (isPlainObject(currValue)) {
      ;(object as any)[currKey] = convertAllDatesInObjectRecursive((object as any)[currKey]);
    } else if (isArray(currValue)) {
      for (let i = 0; i < currValue.length; i++) {
        ;(object as any)[currKey][i] = convertAllDatesInObjectRecursive((object as any)[currKey][i]);
      }
    }
  }
  return object;
};

export const getDateInDefaultBrowserDateFormat = (date: Date | string): string => {
  const dateToFormat = new Date(date);
  return dayjs(dateToFormat).isValid()
    ? new Intl.DateTimeFormat(navigator.language, {
      month: "short", day: "2-digit", year: "numeric"
    }).format(dateToFormat)
    : i18n.t("errors.invalidDate");
};
